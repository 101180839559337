//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search'
import Product2  from '@/components/Product2'

export default {
    name: 'Product',
    components: {Search, Product2},
    data() {
        return {
			uniacid:0,
            //uniacid:17,
			webset:[],
            leimugoodslist: [],
            productInfo: null,
            goodsId: '',
            detail: '',
            goods: '',
            number: 1,
            color: '',
            location: '',
            goods_sku_key: '',
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
            currency: window.localStorage.getItem('currency')
        }
    },
    created() {
        this.goodsId = this.$route.query.id
		var ss = window.localStorage.getItem('webinfo');
		var ssarray = JSON.parse(ss)
		 
		this.webset =  ssarray
		this.uniacid = ssarray['ucid']
        //this.uniacid = 17
		
        this.getGoodsDetail()
        this.getGoodsListT("Luggage", "");

    },
    methods: {
        //下单
        orderConfirm() {
            this.onConfirmClick('buy')
        },

        async onConfirmClick(code) {
            const res = await this.$http.post('/cart/add', {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                color: this.$refs.colorselect.value,
                location: this.$refs.locationselect.value,
                promotion_code:  this.productInfo.basic.field.promotion_code,
                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            })
            // 添加 res
            if (res.code !== 0) {
                 return this.$message.error(res.message);
            }else{
                // this.$router.push({name: 'Cart'})
            }
            if (code === 'buy') {
                this.$router.push({name: 'orderConfirm', params:{cart_id: res.data.cart_id}})
            }
        },

        //修改數量
        changeNumber(type) {
            if(type == 'add'){
                this.number += 1
            }else{
                this.number>1 && (this.number -= 1)
            }
        },
        // 获取商品信息
        async getGoodsDetail() {
            if (!this.goodsId) return
            const res = await this.$http.get('/goods/detail', {
                goods_id: this.goodsId
            })
            let data = res.data
            data.basic.field.detail_mobile = JSON.parse(data.basic.field.detail_mobile)
            this.detail=  data.basic.field.detail        
            this.goods = data.basic.field
            this.response = data
            this.productInfo = res.data

            let uniacid =window.localStorage.getItem('uniacid');
            //let uniacid =17;

            if(this.locale === 'zh-CN') {
                const goods_name = chineseLanguageLoader(this.productInfo.basic.field.goods_name, { language: 'zh-CN' });
                this.productInfo.basic.field.goods_name = goods_name;

                const detail = chineseLanguageLoader(this.productInfo.basic.field.detail, { language: 'zh-CN' });
                this.productInfo.basic.field.detail = detail;
            }
            else if(this.locale === 'EN' && uniacid == 17) {
                //console.log('this.goodsId: ' + this.goodsId)
                if(this.goodsId === "NS_01") {
                    this.productInfo.basic.field.goods_name = "Slim Head Toothbrush";
                    this.productInfo.basic.field.detail = "Prefer an adult toothbrush with a small brush head. Suitable for consumers with sensitive throats and vomiting reactions. Ergonomic handle suitable for children and adults.<br /><br />TePe toothbrushes can heat the toothbrush neck with hot water, bend it back to the desired angle, and then rinse it with cold water to cool it down.";
                }
                else if(this.goodsId === "NS_02") {
                    this.productInfo.basic.field.goods_name = "Double Layer Bristle Toothbrush";
                    this.productInfo.basic.field.detail = "The ePe Supreme™ Double-Bristled Toothbrush features an innovative double-bristle design with fine, pointed bristles that clean deeply between teeth along the gum line, while shorter bristles gently clean the remaining tooth surfaces. At the same time, the toothbrush is equipped with a tapered brush head design and a stable, non-slip grip with thumb pads.";
                }
                else if(this.goodsId === "NS_03") {
                    this.productInfo.basic.field.goods_name = "Deep Cleansing Toothbrush (Super Soft Bristles)";
                    this.productInfo.basic.field.detail = "Prefer an adult toothbrush with a small brush head. Suitable for consumers with sensitive throats and vomiting reactions. Ergonomic handle suitable for children and adults.<br /><br />TePe toothbrushes can heat the toothbrush neck with hot water, bend it back to the desired angle, and then rinse it with cold water to cool it down.";
                }
                else if(this.goodsId === "NS_04") {
                    this.productInfo.basic.field.goods_name = "Deep Cleansing Toothbrush (Regular Bristles)";
                    this.productInfo.basic.field.detail = "The TePe Nova Deep Cleansing Toothbrush is equipped with specially pointed bristles to clean hard-to-reach areas of your mouth, especially the back teeth. At the same time, the tapered brush head design and anti-slip handle are ergonomic.";
                }
                else if(this.goodsId === "NS_05") {
                    this.productInfo.basic.field.goods_name = "Toothbrush (Soft Bristles)";
                    this.productInfo.basic.field.detail = "Double layer super soft bristles<br /><br />Long bristle design cleans deeply between teeth<br /><br />Short flat bristles clean tooth surfaces<br /><br />Tapered brush head reaches deep into back teeth<br /><br />Ergonomic handle design, also has anti-slip function<br /><br />The direct angle of the toothbrush head can be adjusted directly";
                }
                else if(this.goodsId === "NS_06") {
                    this.productInfo.basic.field.goods_name = "Long Handle Interdental Brush - 0.6MM (pack of 6)";
                    this.productInfo.basic.field.detail = "The L-shaped design of the TePe Angle™ long-handled interdental brush makes it easier for the interdental brush to penetrate deeply into the interdental area for cleaning, especially the back teeth area. The slender and curved brush head can easily clean the back teeth and interdental spaces from the inside to the outside.<br /><br />Plus, the long, flat handle is ergonomically designed to help you grip the interdental brush.<br /><br />Each interdental brush wire is covered with safe plastic coating and will not irritate teeth.";
                }
                else if(this.goodsId === "NS_07") {
                    this.productInfo.basic.field.goods_name = "Long Handle Interdental Brush - 0.7MM (pack of 6)";
                    this.productInfo.basic.field.detail = "The L-shaped design of the TePe Angle™ long-handled interdental brush makes it easier for the interdental brush to penetrate deeply into the interdental area for cleaning, especially the back teeth area. The slender and curved brush head can easily clean the back teeth and interdental spaces from the inside to the outside.<br /><br />Plus, the long, flat handle is ergonomically designed to help you grip the interdental brush.<br /><br />Each interdental brush wire is covered with safe plastic coating and will not irritate teeth.";
                }
                else if(this.goodsId === "NS_08") {
                    this.productInfo.basic.field.goods_name = "Long Handle Interdental Brush - 0.5MM (pack of 6)";
                    this.productInfo.basic.field.detail = "The L-shaped design of the TePe Angle™ long-handled interdental brush makes it easier for the interdental brush to penetrate deeply into the interdental area for cleaning, especially the back teeth area. The slender and curved brush head can easily clean the back teeth and interdental spaces from the inside to the outside.<br /><br />Plus, the long, flat handle is ergonomically designed to help you grip the interdental brush.<br /><br />Each interdental brush wire is covered with safe plastic coating and will not irritate teeth.";
                }
            }
            

        },

        //添加到購物車
        async addCart(code) {
            const res = await this.$http.post('/cart/add', {
                user_id: window.localStorage.getItem('user_id'),
                goods_id: this.goodsId,
                goods_sku_key: this.productInfo.sku.list[0].goods_sku_key,
                goods_number: this.number,
                color: this.$refs.colorselect.value.toString(),
                /*location: this.$refs.locationselect.value.toString(),*/
                promotion_code:  this.productInfo.promotion_code,
                is_checked: 1,
                is_direct: code === 'buy' ? 1 : 0
            })
            console.log(res)
            if (res.code != 0) {
                if (res.data.code === 401) {
                    window.location.href='/#/login'
                    return false
                }
                return this.$message.error(res.message);
            }else{

                this.$message({message: '添加成功', type: 'success'})
            }
        },

        async getGoodsListT(one,two) {
        one = this.uniacid;
            if (one == 4){
            one = "A11"
        }
        else if (one == 5) {
            one = "none"
        }
        else if (one == 6) {
            one = "Curtain rod"
        }
        else if (one == 7) {
            one = "AB"
        }
        else if (one == 8) {
            one = "warehouse"
        }
        else if (one == 9) {
            one = "jiankang"
        }
        else if (one == 10) {
            one = "YTOFFICE"
        }
        else if (one == 11) {
            one = "無綫連接儀錶 ( 外置藍牙模組 )"
        }
        else if (one == 12) {
            one = "15g Tube"
        }
        else if (one == 13) {
            one = "none"
        }
        else if (one == 14) {
            one = "Rake"
        }
        else if (one == 15) {
            one = "消毒儀器"
        }
        else if (one == 16) {
            one = "新產品"
        }
        else if (one == 17) {
            one = "專業牙刷系列"
        }
        else if (one == 18) {
            one = "Toy Car"
        }
        else if (one == 19) {
            one = "Discount item"
        }
        else if (one == 20) {
            one = "Course"
        }
        else if (one == 21) {
            one = "Insurance Products"
        }
        else if (one == 22) {
            one = "Custom-made"
        }
        else if (one == 23) {
            one = "tea"
        }
        else if (one == 24) {
            one = "Storage"
        }
        else if (one == 25) {
            one = "小電器"
        }
        else if (one == 26) {
            one = "Grain Moisture Meter"
        }
        else if (one == 27) {
            one = "Insurance Services"
        }
        else if (one == 28) {
            one = "Financial Advisory Services"
        }
        else if (one == 29) {
            one = "倫敦"
        }
        else if (one == 30) {
            one = "Earphones"
        }
        else if (one == 31) {
            one = "原創麵譜"
        }
        else if (one == 32) {
            one = "none"
        }
        else if (one == 33) {
            one = "none"
        }
        else {
            one = "none"
        }
			const res = await this.$http.get('/goods', {
				page: 0,
				size: 5,
				category_one: one,
				category_two: two,
                //uniacid: 24,
			})
			console.log('resgoods',res);
			this.isLoading = false
			if (res.code !== 0) return
			//let data2 = res.data
		    this.leimugoodslist=res.data.list
            console.log("leimugoodslist: " + this.uniacid)
            console.log(this.leimugoodslist)

		
		 
		},
    }
}
